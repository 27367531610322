import { inject, Injectable } from '@angular/core';
import { ContactService } from '../feature/openapi/services';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BNContactService {
  private contactService = inject(ContactService);
  private route = inject(Router);


  subscribeToNewsletter(name: string, email: string) {
    const message = `Hírlevél feliratkozás
    Forrás: ${this.route.url}
    Név: ${name}
    E-mail: ${email}`;
    return this.sendToSylius(email, message).pipe(
      map((res: any) => res.status === 202)
    );
  }

  sendContactRequest(name: string, email: string, message: string) {
    const msg = `Kapcsolatfelvételi form beküldés (lásd forrás)
    Forrás: ${this.route.url}
    Név: ${name}
    E-mail: ${email}
    Üzenet: ${message}`;
    return this.sendToSylius(email, msg).pipe(
      map((res: any) => res.status === 202)
    );
  }

  private sendToSylius(email: string, message: string) {
    return this.contactService.shopContactContactCollection$Json$Json$Response(
      {
        'Accept-Language': 'hu_HU',
        body: {
          email,
          message
        }
      }
    );
  }
}
